import { Divider } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { loanTypes } from "../../../../commons/enum";
import { LoadingBackdrop } from "../../../../components/loading-backdrop";
import { useIsDesktopSmall, useIsTablet } from "../../../../hooks/common.hooks";
import { primaryColor } from "../../../../scss/colors.scss";
import { AngleRight } from "../../../../svgs";
import { convertToFloat } from "../../../../utils/convert-number";
import OutcomeDescription from "./outcome-description";
import TopThreeProductItem from "./top-three-product-item";

const TopThreeProduct = ({
  isLoading,
  isError,
  maxLoan = 0,
  cashTowardProperty = 0,
  scenarioObject = {},
  productList = [],
  loanType,
  handleSetSelectedChartTab = () => {},
}) => {
  const isDesktopSmall = useIsDesktopSmall();
  const isTablet = useIsTablet();
  const handleSeeAllOptions = () => {
    handleSetSelectedChartTab(2);
  };
  return (
    <div className="top-three-product-section">
      <div className="top-three-product-container d-block">
        {!isTablet && (
          <div className="d-flex font-size-normal font-weight-bold mb-3">
            <div>Top 3 Loans Available </div>
            <div
              className="see-all-option"
              onClick={() => {
                handleSeeAllOptions();
              }}
              role="button"
              onKeyDown={() => {}}
              tabIndex="0"
            >
              <AngleRight fill={primaryColor} />
              See All Options
            </div>
          </div>
        )}

        <div className="top-three-product-list">
          {productList.length > 0
            ? productList.map((productItem, index) => {
                const {
                  uniqueId = "",
                  monthlyRepaymentList = [],
                  baseRate = 0,
                  maxBorrowCapacity = 0,
                  rates = [],
                  lenderId = "",
                } = productItem;
                const [ratesItem = {}] = rates;
                const { rate = 0 } = ratesItem;
                const [monthlyRepayment] = monthlyRepaymentList;
                return (
                  <div key={`ProductItem${uniqueId}`}>
                    <TopThreeProductItem
                      lender={lenderId}
                      monthlyRepayment={monthlyRepayment}
                      interestedRate={
                        loanType === loanTypes.STANDARD_VARIABLE
                          ? convertToFloat(baseRate, 2)
                          : convertToFloat(rate, 2)
                      }
                      maxLoanAmount={maxBorrowCapacity}
                      isVariable={loanType === loanTypes.STANDARD_VARIABLE}
                    />
                    {index !== productList.length - 1 && <Divider />}
                  </div>
                );
              })
            : !isLoading && (
                <div className="no-lender-option">
                  {isError
                    ? "Failed to get Lender Option."
                    : "No Lender Option available for this Scenario."}
                </div>
              )}
          <LoadingBackdrop isOpen={isLoading} content="Calculating Data..." />
        </div>
      </div>
      {!isDesktopSmall && !isLoading && (
        <div className="outcome-desc-container">
          <OutcomeDescription
            maxLoan={maxLoan}
            cashTowardProperty={cashTowardProperty}
            scenarioObject={scenarioObject}
          />
        </div>
      )}
    </div>
  );
};

TopThreeProduct.propTypes = {
  productList: PropTypes.array,
};

export default TopThreeProduct;
