import React from "react";
import { useServiceProviders } from "../../../../hooks/scenario.hooks";
import { NoImage } from "../../../../images";
import {
  formatNumberWithDollar,
  formatNumberWithPercent,
} from "../../../../utils/numberFormater";
import TopThreeProductItemDescription from "./top-three-product-item-description";

const TopThreeProductItem = ({
  lender = "",
  interestedRate = 0,
  maxLoanAmount = 0,
  monthlyRepayment = 0,
  isVariable = false,
}) => {
  const { data: lenderList = {} } = useServiceProviders();
  const lenderDetail = lenderList[lender] || {};
  const { logo = "" } = lenderDetail;
  return (
    <div className="top-three-item-container">
      <div className="top-three-product-item top-three-item-logo">
        <img src={logo || NoImage} alt={lender} />
      </div>
      <TopThreeProductItemDescription
        value={formatNumberWithPercent(interestedRate)}
        description={isVariable ? "Variable" : "Fixed"}
      />

      <TopThreeProductItemDescription
        value={formatNumberWithDollar(maxLoanAmount)}
        description="Max Loan Amount"
      />

      <TopThreeProductItemDescription
        value={formatNumberWithDollar(monthlyRepayment)}
        description="Monthly Repayments"
      />
    </div>
  );
};

export default TopThreeProductItem;
