import React, { useContext, useEffect, useState } from "react";
import { openPopupWidget } from "react-calendly";
import CallToActionButton from "../../../../components/CallToActionButton/call-to-action-button";
import ScenarioItemContext from "../../../../context/scenario-item-context";
import { useMyBrokerInfo } from "../../../../hooks/broker.hooks";
import { useCompanyInfo, useIsTablet } from "../../../../hooks/common.hooks";

const OutcomeDescription = ({ maxLoan = 0, cashTowardProperty = 0 }) => {
  const isTablet = useIsTablet();
  const { data: brokerInfo } = useMyBrokerInfo();
  const [content, setContent] = useState("");
  const { setTabIndex } = useContext(ScenarioItemContext);
  const handleBookCall = (url) => {
    openPopupWidget({ url });
  };
  const handleStartLoanApplication = () => {
    setTabIndex("loan-application");
  };
  const { data } = useCompanyInfo();
  const isHaveConnective = data?.isHaveConnective;

  useEffect(() => {
    if (maxLoan > 0) {
      setContent(`You have enough income to afford a bank loan`);
    } else {
      setContent(
        `Looks like your income/expense can't automatically match with the bank policies. Let's schedule a call to find the best solution manually`
      );
    }
  }, [maxLoan, cashTowardProperty]);
  return (
    <>
      {!isTablet && (
        <div className="bc-title font-weight-bold outcome-title">Outcome</div>
      )}

      <div className="outcome-desc-content">{content}</div>
      <div className="outcome-desc-btn-group">
        {Boolean(brokerInfo.calendlyUrl) && (
          <CallToActionButton
            title="Talk To Your Broker"
            iconDisabled
            onClick={() => handleBookCall(brokerInfo.calendlyUrl)}
          />
        )}
        {!isTablet && isHaveConnective && (
          <CallToActionButton
            title="Start Loan Application"
            iconDisabled
            onClick={handleStartLoanApplication}
          />
        )}
      </div>
    </>
  );
};

OutcomeDescription.propTypes = {};

export default OutcomeDescription;
