import React from "react";
import PropTypes from "prop-types";

const TopThreeProductItemDescription = ({ value = "", description = "" }) => {
  return (
    <div className="text-center top-three-product-item">
      <div className="market-trend-item-value">{value}</div>
      <div className="market-trend-item-title">{description}</div>
    </div>
  );
};

TopThreeProductItemDescription.propTypes = {
  value: PropTypes.string,
  description: PropTypes.string,
};

export default TopThreeProductItemDescription;
