import PropTypes from "prop-types";
import React from "react";
import "./call-to-action-button.scss";

const CallToActionButton = ({
  iconFirst = false,
  title = "",
  onClick = () => {},
  icon = <></>,
  iconDisabled = false,
  disabled = false,
}) => {
  return (
    <button
      type="button"
      onClick={() => onClick()}
      tabIndex="0"
      onKeyDown={() => {}}
      className="call-to-action-button"
      disabled={disabled}
    >
      {iconFirst && !iconDisabled && (
        <div className="call-to-action-button__icon">{icon}</div>
      )}
      <div className="call-to-action-button__title">{title}</div>
      {!iconFirst && !iconDisabled && (
        <div className="call-to-action-button__icon">{icon}</div>
      )}
    </button>
  );
};

CallToActionButton.propTypes = {
  icon: PropTypes.element,
  iconFirst: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.node,
};

export default CallToActionButton;
